import { io } from "socket.io-client";

const id = localStorage.getItem("id") || "";
const URL = "https://api.pluritongues.com";

export const socket = io(`${URL}/messages?userId=${id}`, {
  transports: ["websocket"],
  reconnection: true,
  reconnectionAttempts: 5,
  secure: URL.startsWith("https"),
  autoConnect: true,
});
