import React from "react";
import Layout from "../../layout/Portal";
import "./styles.scss";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import { useNavigate } from "react-router-dom";

export const Summary = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Button
        onClick={() => navigate(-1)}
        variant="neutral"
        className="go-back"
      >
        <Icon name="left_arrow" />
        <p className="back">Back</p>
      </Button>
      <div className="withdraw-content-container">
        <h3 className="s-title">Withdrawal Confirmation</h3>

        <div className="row">
          <p className="left-text">Amount withdrawn</p>
          <p className="right-text">USD 350.00</p>
        </div>
        <div className="row">
          <p className="left-text">Transaction fee</p>
          <p className="right-text">USD - 5.00</p>
        </div>
        <div className="row">
          <p className="left-text">Emergency withdrawal fee (5%)</p>
          <p className="right-text">USD - 17.50</p>
        </div>
        <div className="row">
          <p className="left-text">You receive</p>
          <p className="right-text">USD - 329.50</p>
        </div>
        <div className="row">
          <p className="left-text">Withdrawal account</p>
          <p className="right-text">Payoneer- 009187289118...</p>
        </div>
        <div className="row">
          <p className="left-text">Payment date</p>
          <p className="right-text">Wed, 14th December 2024</p>
        </div>
        <div className="s-withdraw-container">
          <button onClick={() => {}} className="s-withdraw">
            Proceed
          </button>
        </div>
      </div>
    </Layout>
  );
};
