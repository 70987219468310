import { useNavigate } from "react-router-dom";

import "./checkMail.scss";

export default function CheckMail() {
  const navigate = useNavigate();

  return (
    <div className="layout">
      <div className="check-mail-wrapper side-bar">
        <div
          onClick={() => navigate("/forgot-password")}
          className="back-arrow-wrapper"
        >
          <i className="fas fa-arrow-left back-arrow"></i>
          <div className="back-text">Back</div>
        </div>
        <h1>Check Mail.</h1>
        <p className="auth-p">
          A password reset link has been sent to your inbox, follow the link to
          reset your password
        </p>

        <p className="footer-check-mail">
          &copy; right <a href="https://pluritongues.com">Pluritongues</a>. All
          rights reserved
        </p>
      </div>

      <div className="main-wrapper"></div>
    </div>
  );
}
