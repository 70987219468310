import { useState } from "react";
import "./forgotPassword.scss";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const api = `https://api.pluritongues.com`;
    setError("");
    setLoading(true);

    try {
      const response = await fetch(`${api}/auth/forgot-password-merchant`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        navigate("/check-mail");
      } else {
        setError("Invalid email");
      }
    } catch (err: any) {
      setError(
        "Something went wrong. Check your network connection and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="layout">
      <div className="forgot-password-wrapper side-bar">
        <div className="back-arrow-wrapper" onClick={() => navigate("/")}>
          <i className="fas fa-arrow-left back-arrow"></i>
          <div className="back-text">Back</div>
        </div>

        <h1>Forgot Password?</h1>
        <p>
          Please type in the email address linked to your PLURITONGUES account
        </p>

        <form onSubmit={handleSubmit} className="form-wrapper">
          <label>Email</label>
          <input
            className="password-input"
            type="email"
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            autoFocus
          />

          {error && <div className="error">{error}</div>}

          <button
            type="submit"
            // disabled={loading}
            className="button"
          >
            {/* Reset Password */}
            {loading ? "Loading..." : "Reset Password"}
          </button>
        </form>

        <p className="footer-forgot-password">
          &copy; right <a href="https://pluritongues.com">Pluritongues</a>. All
          rights reserved
        </p>
      </div>
      <div className="main-wrapper"></div>
    </div>
  );
}
