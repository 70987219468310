import React, { useState, useEffect, useCallback } from "react";
import {
  format,
  addMonths,
  subMonths,
  addDays,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isToday,
  isSameDay,
} from "date-fns";
import "./styles.scss";
import Layout from "../layout/Portal";
import Switch from "react-switch";
import {
  convertIndexToSession,
  formatDateToLong,
  generateEndTimeSlots,
  generateTimeSlots,
  getWeekdayTimeSlots,
} from "../../utils/helpers";
import { days, dummySession } from "./days";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Availability = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [checked, setChecked] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(
    new Date().toLocaleDateString()
  );
  const [startTime, setStartTime] = useState<any>("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>([]);
  const [startEndTime, setStartEndTime] = useState<any>([
    { end: "", start: "", day: "" },
  ]);

  const handleChange = (checked: boolean) => {
    setSelectedDate(
      checked
        ? new Date(
            new Date().setDate(new Date().getDate() + 1)
          ).toLocaleDateString()
        : new Date().toLocaleDateString()
    );
    setChecked(checked);
  };

  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const startDate = startOfWeek(startOfMonth(currentDate));
  const endDate = endOfWeek(endOfMonth(currentDate));

  const generateCalendar = () => {
    let day = startDate;
    const days = [];

    while (day <= endDate) {
      days.push(day);
      day = addDays(day, 1);
    }
    return days;
  };

  const calendarDays = generateCalendar();

  const dateHandler = (day: any, prev: any) => {};

  const [timeStartHandler, updateTimeStartHandler] = useState<any>([]);
  const [timeEndHandler, updateTimeEndHandler] = useState<any>([]);
  const [daySelect, updateDaySelect] = useState<any>("");
  const [timeFrame, updateTimeFrame] = useState<any>({
    from: "",
    to: "",
  });
  const [pickedDates, updatePickedDates] = useState<any>([]);

  const _timeStartAppend = useCallback(() => {
    const lastEndTime =
      startEndTime.length > 0
        ? startEndTime[startEndTime.length - 1].end
        : null;

    const filteredStartTimes = generateTimeSlots(
      selectedDate,
      startEndTime
    ).filter((slot: string) => {
      return !startEndTime.some(
        (timeSlot: any) =>
          timeSlot.start === slot || (lastEndTime && slot < lastEndTime)
      );
    });

    if (lastEndTime && !filteredStartTimes.includes(lastEndTime)) {
      filteredStartTimes.push(lastEndTime);
    }

    updateTimeStartHandler(filteredStartTimes);
  }, [selectedDate, startEndTime]);

  const _timeEndAppend = useCallback(() => {
    if (
      startEndTime.length > 0 &&
      startEndTime[startEndTime.length - 1].start
    ) {
      const currentStart = startEndTime[startEndTime.length - 1].start;

      const filteredEndTimes = generateEndTimeSlots(currentStart).filter(
        (slot: string) => {
          return !startEndTime.some((timeSlot: any) => {
            const start = timeSlot.start;
            const end = timeSlot.end;

            return (start <= slot && slot <= end) || slot <= currentStart;
          });
        }
      );

      updateTimeEndHandler(filteredEndTimes);
    }
  }, [startEndTime]);

  useEffect(() => {
    _timeStartAppend();
    _timeEndAppend();
  }, [selectedDate, startEndTime]);

  useEffect(() => {
    _timeEndAppend();
  }, [startEndTime]);

  const checkdayTime = (value: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return value < today;
  };
  const compareDate = (value: any) => {
    const today = new Date(timeFrame.from);
    today.setHours(0, 0, 0, 0);
    return value < today;
  };

  const renderCalendar = () => {
    return calendarDays.map((day, index) => {
      const isHighlighted = dummySession?.filter((highlightedDate) =>
        isSameDay(day.toLocaleDateString(), highlightedDate.day)
      );

      return (
        <div
          key={index}
          className={`day ${isSameMonth(day, selectedDate) ? "" : "disabled"} ${
            isSameDay(day, selectedDate) ? "today" : "notToday"
          }`}
          onClick={() => {
            if (checkdayTime(day)) {
              alert(
                "You cannot select a past date. Please select a later date."
              );
              const newDate = new Date(
                new Date().setDate(new Date().getDate() + 1)
              ).toLocaleDateString();

              setSelectedDate(
                checked ? newDate : new Date().toLocaleDateString()
              );
            } else {
              setSelectedDate((prev: any) => {
                if (prev !== day.toLocaleDateString()) {
                  setStartEndTime([{ end: "", start: "", day: "" }]);
                }
                return day.toLocaleDateString();
              });
            }

            if (isHighlighted.length > 0) {
              setTimeout(() => {
                setStartEndTime(isHighlighted);
                updatePickedDates(isHighlighted);
                setSelectedDate(isHighlighted[0].day);
              }, 0);
            } else {
              updatePickedDates([]);
            }
          }}
        >
          <span
            className={
              isSameDay(day, selectedDate) ? "day-number-picked" : "day-number"
            }
          >
            {format(day, "EEE")}
          </span>
          <span
            className={
              isSameDay(day, selectedDate) ? "day-number-picked" : "day-number"
            }
          >
            {format(day, "d")}
          </span>
          {isHighlighted.length > 0 ? (
            <div
              className={
                isSameDay(day, selectedDate) ? "day-text-picked" : "day-text"
              }
            >
              {isHighlighted && `${isHighlighted.length} session(s)`}
            </div>
          ) : null}
        </div>
      );
    });
  };

  const _deleteHandler = (index: any) => {
    if (index < 1) return;
    setStartEndTime((prevTimes: any[]) =>
      prevTimes.filter((_, i) => i !== index)
    );
  };

  return (
    <Layout>
      <div className="calendar-container">
        <div className="calendar-header">
          <button onClick={prevMonth} className="month-nav">
            <FontAwesomeIcon
              icon={["fas", "chevron-left"]}
              className="sideIcon"
            />
          </button>
          <h6 className="month">{format(currentDate, "MMMM")}</h6>
          <h6 className="year">{format(currentDate, "yyyy")}</h6>
          <button onClick={nextMonth} className="month-nav">
            <FontAwesomeIcon
              className="sideIcon"
              icon={["fas", "chevron-right"]}
            />
          </button>
        </div>

        <div className="weekDay">
          <div className="calendar-grid">{renderCalendar()}</div>
        </div>
      </div>

      <div className="bottomContainer">
        <div className="leftContainer">
          <p className="session">Booked Sessions</p>
          {pickedDates.length > 0 && formatDateToLong(pickedDates[0].day)}
          {pickedDates.length > 0
            ? pickedDates.map((item: any, index: any) => (
                <div key={index}>
                  <p key={index} className="mapped">
                    {convertIndexToSession(index)}
                  </p>
                  <div className="iconSessions">
                    <p key={index} className="mappedSession">
                      {`${item.start} to ${item.end}`}
                    </p>
                    <FontAwesomeIcon
                      className="icon"
                      icon={["far", "pen-to-square"]}
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className="rightContainer">
          <div className="switchCon">
            <Switch
              offColor={"#E1E8ED"}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={handleChange}
              checked={checked}
              height={26}
            />
            <p className="unavailable">Set today as Unavailable</p>
          </div>
          {startEndTime.map((item: any, index: any) => {
            return (
              <div key={index} className="input">
                <div className="inputLeft">
                  <label>
                    Open Time <br />
                    <select
                      className="selectInput"
                      value={item.start}
                      onChange={(e) =>
                        setStartEndTime((prevValues: any) =>
                          prevValues.map((item: any, i: number) =>
                            i === index
                              ? {
                                  ...item,
                                  start: e.target.value,
                                  end: "",
                                  day: selectedDate,
                                }
                              : i > index
                              ? { end: "", start: "", day: "" } // Clear subsequent objects
                              : item
                          )
                        )
                      }
                    >
                      <option
                        disabled={item.start ? true : false}
                        value="Start time"
                      >
                        Start Time
                      </option>
                      {item.start && (
                        <option key="selected" value={item.start}>
                          {item.start}
                        </option>
                      )}
                      {timeStartHandler?.map((slot: any, idx: any) => (
                        <option key={idx} value={slot}>
                          {slot}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Close Time <br />
                    <select
                      disabled={item.start ? false : true}
                      className="selectInput"
                      value={item.end}
                      onChange={(e) => {
                        setStartEndTime((prevValues: any) =>
                          prevValues.map((item: any, i: number) =>
                            i === index
                              ? {
                                  ...item,
                                  end: e.target.value,
                                  day: selectedDate,
                                }
                              : item
                          )
                        );
                      }}
                    >
                      <option
                        disabled={item.end ? true : false}
                        value="End time"
                      >
                        End Time
                      </option>
                      {item.end && (
                        <option key="selected-end" value={item.end}>
                          {item.end}
                        </option>
                      )}
                      {timeEndHandler?.map((slot: any, idx: any) => (
                        <option key={idx} value={slot}>
                          {slot}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                {index < 1 ? null : (
                  <FontAwesomeIcon
                    className="deleteIcon"
                    icon={["fas", "trash"]}
                    onClick={() => _deleteHandler(index)}
                  />
                )}
              </div>
            );
          })}
          <div className="outterBtnContainer">
            {startEndTime.length > 1 && (
              <button
                className="resetBtn"
                onClick={() =>
                  setStartEndTime([{ end: "", start: "", day: "" }])
                }
              >
                <p>Reset</p>
              </button>
            )}
            <button
              className="btn"
              onClick={() =>
                setStartEndTime((prevValues: any) => [
                  ...prevValues,
                  { end: "", start: "", day: "" },
                ])
              }
            >
              <p>+ add time slot</p>
            </button>
          </div>
          <div className="line" />

          <label>
            Repeat <br />
            <select
              className="selectInput"
              onChange={(e) => updateDaySelect(e.target.value)}
            >
              <option disabled={daySelect ? true : false} value={daySelect}>
                Select Day
              </option>
              {days.map((item: any, index: any) => (
                <option value={item.day} key={index}>
                  {`Every ${item.day}`}
                </option>
              ))}
            </select>
          </label>

          {daySelect ? (
            <div className="input">
              <label>
                Time Frame (From) <br />
                <input
                  onChange={(e) => {
                    if (checkdayTime(new Date(e.target.value))) {
                      alert(
                        " You can not select a past date, Please select a later date"
                      );
                      updateTimeFrame({
                        ...timeFrame,
                        from: "",
                      });
                    } else {
                      updateTimeFrame({
                        ...timeFrame,
                        from: e.target.value,
                      });
                    }
                  }}
                  className="selectInput"
                  type="date"
                  value={timeFrame.from}
                />
              </label>
              <label>
                Time Frame (To) <br />
                <input
                  onChange={(e) => {
                    if (compareDate(new Date(e.target.value))) {
                      alert(
                        " You can not select a date, That goes beyond from that"
                      );
                      updateTimeFrame({
                        ...timeFrame,
                        to: "",
                      });
                    } else {
                      updateTimeFrame({
                        ...timeFrame,
                        to: e.target.value,
                      });
                    }
                  }}
                  className="selectInput"
                  type="date"
                  value={timeFrame.to}
                />
              </label>
            </div>
          ) : null}
          <div className="buttons">
            <button
              onClick={() => {
                updateDaySelect("");
                updateTimeFrame({
                  from: "",
                  to: "",
                });
              }}
              className="discardBtn"
            >
              <p className="discardText">Discard</p>
            </button>
            <button
              onClick={() =>
                console.log(
                  getWeekdayTimeSlots(timeFrame, daySelect, startEndTime)
                )
              }
              className="saveBtn"
            >
              <p className="saveText">Save</p>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
