import Layout from "../../layout/Portal";
import "./styles.scss";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import { useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import { useCallback, useEffect, useState } from "react";
import "react-toggle/style.css";
import { currencyFormat } from "../../../utils/helpers";
import { toast } from "sonner";

export const Withdraw = () => {
  const navigate = useNavigate();
  const [values, updateValues] = useState({
    data: "",
    loading: false,
    error: "",
  });
  const [toggle, setToggle] = useState(false);
  const [amount, setAmount] = useState("");

  const _submitHandler = () => {
    if (parseInt(amount) < 60 || !amount) {
      toast.warning("Minimum withdrawal amount is 60 USD", {
        richColors: true,
      });
    } else if (parseInt(amount) > parseInt(values.data)) {
      toast.warning(
        "You cannot initiate a withdrawal more than your current balance!",
        {
          richColors: true,
        }
      );
    } else {
      navigate("./summary");
    }
  };

  const fetchBalance = useCallback(async () => {
    const token = await localStorage.getItem("accessToken");
    const id = await localStorage.getItem("id");
    updateValues({ ...values, loading: true, error: "" });
    const url = `https://pluri-api-service.onrender.com/teachers/balance/${id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result) {
        updateValues({
          ...values,
          data: result.result,
          loading: false,
        });
      } else {
        updateValues({
          ...values,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateValues({
        ...values,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  }, []);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return (
    <Layout>
      <Button
        onClick={() => navigate(-1)}
        variant="neutral"
        className="go-back"
      >
        <Icon name="left_arrow" />
        <p className="back">Back</p>
      </Button>
      {values.loading ? (
        <p>Loading</p>
      ) : (
        <div className="withdraw-content-container">
          <h3 className="w-title">Withdraw Your Earnings</h3>
          <p className="w-message">
            Withdrawals are processed on the 14th and 28th of each month.
            Requests made within 48 hours before these dates will be processed
            on the next payout day, except in cases of emergencies, which are
            processed within 48 hours. You will be notified once your withdrawal
            is completed. For any delays or inquiries, please contact our
            support team.
          </p>

          <p className="w-balance">Wallet Balance</p>
          <p className="w-amount">{currencyFormat(parseInt(values.data))}</p>

          <label className="w-label">Enter withdrawal amount (USD)</label>
          <br />

          <input
            value={amount}
            defaultValue={amount}
            onChange={(e) => {
              const replacedText = e.target.value
                .replace(/[^0-9.]/g, "")
                .replace(/^0(?!\.)/, "")
                .replace(/(\.\d{2})\d+/, "$1")
                .replace(/^([1-9]\d*(\.\d{0,2})?).*$/, "$1");
              console.log(replacedText);
              setAmount(replacedText);
            }}
            className="w-input"
            placeholder="E.g. 30"
            type="number"
          />

          <p className="w-account">Withdrawal account</p>
          <div className="w-user-account">
            <p className="w-account-details">
              Payoneer- 0091872891182-Okonkwo Kenechi
            </p>
            <p className="w-change-account">Change</p>
          </div>
          <div className="w-emergency-container">
            <div>
              <p className="w-emergency">Emergency Withdrawal</p>
              <p className="w-48hrs">Process within 48hrs</p>
            </div>
            <label>
              <Toggle
                defaultChecked={toggle}
                icons={false}
                onChange={() => setToggle(!toggle)}
              />
            </label>
          </div>

          {toggle ? (
            <div>
              <p className="w-notice">Emergency Withdrawal Fee Notice!</p>
              <p className="w-notice-subtitle">
                Choosing an emergency withdrawal will include an additional fee
                of 5% of withdrawal amount, this is to ensure faster processing
                to meet your urgent needs.{" "}
              </p>
            </div>
          ) : null}
          <div className="w-proceed-container">
            <button onClick={() => _submitHandler()} className="w-proceed">
              Proceed
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};
