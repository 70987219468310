import { useState } from "react";
import Layout from "../layout/Portal";
import "./styles.scss";
// import { AppLoader } from "../../components/AppLoader";
import TopNav, { Item as NavItem } from "../../components/TopNav";
import List from "../../components/List";
import ScheduleCard from "./ScheduleCard";
import { sortLessons } from "../../utils/helpers";
import axios from "axios";
import { ILesson } from "../../types/schedule";
import { useQuery } from "@tanstack/react-query";

const nav_items = [
  {
    name: "upcoming",
    label: "Upcoming",
  },
  {
    name: "completed",
    label: "Completed",
  },
];

export const Schedule = () => {
  const [selectedNavItem, setSelectedNav] = useState<NavItem>(nav_items[0]);

  const fetchCompletedLessons = async () => {
    try {
      const { data } = await axios(
        `${process.env.REACT_APP_API_BASE_URL}/bookings/merchants?completed=true`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      return data.result.bookings as ILesson[];
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const { data: _completed_lessons } = useQuery({
    queryKey: ["merchant_schedule_completed"],
    queryFn: fetchCompletedLessons,
  });

  const fetchIncompleteLessons = async () => {
    try {
      const { data } = await axios(
        `${process.env.REACT_APP_API_BASE_URL}/bookings/merchants?completed=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      return data.result.bookings as ILesson[];
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const { data: _upcoming_lessons } = useQuery({
    queryKey: ["merchant_schedule_upcoming"],
    queryFn: fetchIncompleteLessons,
  });

  const sortedCompletedLessons = [...(_completed_lessons ?? [])].sort(
    sortLessons
  );
  const sortedIncompleteLessons = [...(_upcoming_lessons ?? [])].sort(
    sortLessons
  );

  return (
    <Layout color={"inherit"}>
      <TopNav
        nav_id="schedule-nav"
        items={nav_items}
        onChange={(value) => setSelectedNav(value)}
        selectedItem={selectedNavItem}
        style={{ marginBottom: 32 }}
      />
      <List
        list_key={`schedule ${selectedNavItem.name}`}
        items={
          selectedNavItem.name === "completed"
            ? sortedCompletedLessons
            : sortedIncompleteLessons
        }
        ItemRender={ScheduleCard}
        emptyMessage={
          selectedNavItem.name === "completed"
            ? "You have no completed lessons yet."
            : "You do not have any upcoming lessons."
        }
      />
    </Layout>
  );
};
