import { library } from "@fortawesome/fontawesome-svg-core";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faCoffee, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import {
  faTrash,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faCheckDouble,
  faClock,
  faCircle,
  faVideo,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane, faClone } from "@fortawesome/free-regular-svg-icons";

library.add(
  faPenToSquare,
  faCoffee,
  faTrash,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faCheck,
  faCheckDouble,
  faClock,
  faCircleNotch,
  faCircle,
  faClone,
  faVideo,
  faCamera
);
