import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Login,
  Lessons,
  Home,
  Schedule,
  Availability,
  Message,
  Profile,
} from "./pages";
import { socket } from "./socket";
import ProtectedRoute from "./components/ProtectedRoute";
import { useState, useEffect, Fragment } from "react";
import ScheduleDetail from "./pages/schedule/[lesson_id]";
import { LanguageContextProvider } from "./context/LanguagesContext";
import { Toaster } from "sonner";
import ForgotPassword from "./pages/forgot-password";
import CheckMail from "./pages/check-mail";
import PasswordReset from "./pages/password-reset";
import { Withdraw } from "./pages/home/withdraw";
import { Summary } from "./pages/home/withdraw-summary";

function App() {
  const [isConnected, setIsConnected] = useState<any>(socket.connected);
  const [fooEvents, setFooEvents] = useState<any>([]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent(value: any) {
      setFooEvents((previous: any) => [...previous, value]);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("foo", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
    };
  }, []);

  return (
    <>
      <Toaster position="top-right" />
      <LanguageContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route path="/check-mail" element={<CheckMail />} />

            <Route path="/password-reset" element={<PasswordReset />} />

            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/lessons"
              element={
                <ProtectedRoute>
                  <Lessons />
                </ProtectedRoute>
              }
            />
            <Route
              path="/schedule"
              element={
                <ProtectedRoute>
                  <Schedule />
                </ProtectedRoute>
              }
            />
            <Route
              path="/schedule/:lesson_id"
              element={
                <ProtectedRoute>
                  <ScheduleDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/availability"
              element={
                <ProtectedRoute>
                  <Availability />
                </ProtectedRoute>
              }
            />
            <Route
              path="/message"
              element={
                <ProtectedRoute>
                  <Message isConnected={isConnected} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/withdraw"
              element={
                <ProtectedRoute>
                  <Withdraw />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/withdraw/summary"
              element={
                <ProtectedRoute>
                  <Summary />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </LanguageContextProvider>
    </>
  );
}

export default App;
