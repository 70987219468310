import { useState } from "react";
import { NavLink } from "react-router-dom";
import SignUpLogo from "../../components/SignUpLogo";
import Icon from "../../components/Icon";
import "./portal.scss";

export default function Layout({ children, color }: any) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="dashboard">
      <button className="icon-button ham" onClick={toggleSidebar}>
        <Icon name={"menu"} />
      </button>

      <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="sidebar-header">

          {isSidebarOpen ? (
            <button className="icon-button close" onClick={toggleSidebar}>
              <Icon name={"close"} />
            </button>
          ) : (
            <SignUpLogo />
          )}
          
        </div>
        <nav className="sidebar-nav">
          <ul>
            <li>
              <NavLink
                to="/home"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/lessons"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Lessons
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/schedule"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Schedule
              </NavLink>
            </li>
            {isSidebarOpen ? null : (
              <li>
                <NavLink
                  to="/availability"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  Availability
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                to="/message"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Message
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Profile
              </NavLink>
            </li>
          </ul>
        </nav>
      </aside>

      <div className={`main-content`}>
        <section
          style={{ backgroundColor: color || "white", marginTop: 40 }}
          className="dashboard-content"
        >
          {children}
        </section>
      </div>
    </div>
  );
}
