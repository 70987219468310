import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SignUpLogo from "../../components/SignUpLogo";
import { Spinner } from "../../components";
import "./login.scss";

export const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>("password");

  const navigate = useNavigate();

  function handleToggle() {
    setShowPassword(!showPassword);
    setInputType(showPassword ? "password" : "text");
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const url = "https://api.pluritongues.com/auth/login-merchant";
    const payload = { email, password };
    setError(undefined);
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Invalid credentials");
      }
      const data = await response.json();
      localStorage.setItem("accessToken", data.result.accessToken.accessToken);
      localStorage.setItem("id", data.result.accessToken.merchant.id);
      navigate("/home");
    } catch (err: any) {
      console.log("Error at the catch block is:" + " " + err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="layout">
      <div className="login-wrapper side-bar">
        <div className="logo-wrapper">
          <SignUpLogo />
        </div>

        <form className="form-wrapper" onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}

          <label>Email</label>
          <input
            className="password-input"
            type="email"
            value={email}
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
            required
          />

          <label>Password</label>
          <input
            className="password-input"
            type={inputType}
            value={password}
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <span onClick={handleToggle} className="show-toggle">
            {showPassword ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </span>

          <button className="button" type="submit" disabled={loading}>
            {loading ? "Loading..." : "Sign In"}
          </button>
        </form>

        <div className="already-have">
          Can't remember your password?
          <Link className="recover" to="/forgot-password">
            <span> Recover it</span>.
          </Link>
        </div>

        <div className="register-text">
          <a
            className="register"
            href="https://register-teacher.pluritongues.com"
          >
            Register here
          </a>
        </div>

        <p className="footer-login">
          &copy; right <a href="https://pluritongues.com">Pluritongues</a>. All
          rights reserved
        </p>
      </div>
      <div className="main-wrapper"></div>
    </div>
  );
};
