import { useNavigate } from "react-router-dom";
import Layout from "../layout/Portal";
import BalanceCard from "./balance-card/BalanceCard";
import Card from "./card/Card";
import "./home.scss";

export const Home = () => {
  const balance = 0; // Example balance
  const completedClasses = 0;

  const navigate = useNavigate();

  const handleWithdraw = () => {
    navigate("./withdraw"); // You can replace this with real withdrawal logic, e.g., open a modal or redirect.
  };

  return (
    <Layout>
      <h2>Welcome to your Dashboard</h2>

      {/* Container for the cards */}
      <div className="dashboard-cards">
        {/* Display the balance card */}
        <BalanceCard balance={balance} onWithdraw={handleWithdraw} />

        {/* Display the transactions card */}
        <Card totalTransactions={completedClasses} />
      </div>
    </Layout>
  );
};
