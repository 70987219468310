import { useEffect, useMemo, useState } from "react";
import { ILesson } from "../../../types/schedule";
import { useNavigate } from "react-router-dom";
import {
  formatDateString,
  formatTimeSlot,
  isLessonInPast,
  isLessonInProgress,
} from "../../../utils/helpers";
import "./index.scss";
import useLanguages from "../../../context/LanguagesContext";

type StatusType = "completed" | "upcoming" | "in progress" | "action required";

export default function ScheduleCard({ item: lesson }: { item: ILesson }) {
  const { languages_taught } = useLanguages();
  const navigate = useNavigate();
  const [lessonStatus, setLessonStatus] = useState<StatusType>(
    isLessonInProgress(lesson)
      ? "in progress"
      : isLessonInPast(lesson) && !lesson.completed
      ? "action required"
      : lesson.completed
      ? "completed"
      : "upcoming"
  );
  useEffect(() => {
    const interval = setInterval(() => {
      const status = isLessonInProgress(lesson)
        ? "in progress"
        : isLessonInPast(lesson) && !lesson.completed
        ? "action required"
        : lesson.completed
        ? "completed"
        : "upcoming";
      setLessonStatus(status);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => navigate(`./${lesson.id}`);

  const language = useMemo(
    () =>
      languages_taught.find((language) => language.id === lesson.languageid),
    [languages_taught, lesson.languageid]
  );

  return (
    <div
      className="schedule-card--container"
      data-status={lessonStatus}
      onClick={handleClick}
    >
      <div className="row">
        <div className="left">
          <Status status={lessonStatus} />
          <div className="date">
            {formatDateString(new Date(lesson.time.start))}
          </div>
          <div className="time">
            <span>{formatTimeSlot(new Date(lesson.time.start))}</span> -{" "}
            <span>{formatTimeSlot(new Date(lesson.time.end))}</span>
          </div>
        </div>

        <h1 className="language">{language?.name}</h1>
      </div>
    </div>
  );
}

const Status = ({ status }: { status: StatusType }) => {
  return (
    <div className="status-container" data-status={status}>
      <div className="status-dot" data-status={status}></div>
      <span className="status-text"> {status}</span>
    </div>
  );
};
