import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { createContext, ReactNode, useContext } from "react";
import { ILanguage } from "../types/language";

interface ILanguageContext {
  languages_taught: ILanguage[];
}

const INITIAL_STATE = {
  languages_taught: [],
};

export const LanguageContext = createContext<ILanguageContext>(INITIAL_STATE);

export function LanguageContextProvider({ children }: { children: ReactNode }) {
  const fetchLanguages = async () => {
    try {
      const { data } = await axios(
        `${process.env.REACT_APP_API_BASE_URL}/languages/languages-taught/`
      );

      return data.result.languages as ILanguage[];
    } catch (error) {
      throw error;
    }
  };

  const { data: languages_taught } = useQuery({
    queryKey: ["languages-taught"],
    queryFn: fetchLanguages,
  });
  return (
    <LanguageContext.Provider
      value={{ languages_taught: languages_taught ?? [] }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export default function useLanguages() {
  return useContext(LanguageContext);
}
