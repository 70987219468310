import Logo from "../img/LogoSvg.png";


function SignUpLogo (){
    return (
       <div className=''>
          <img
          src={Logo}
          alt='logo'
          style={{
            width: "150px",
            marginBottom: "10px",
            marginTop: "5px"
          }}
           />
       </div>
    )
}

export default SignUpLogo;