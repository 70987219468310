import "./index.scss";

interface ListProps<T> {
  items: T[];
  ItemRender: (props: { item: T }) => JSX.Element;
  list_key: string;
  emptyMessage?: string;
}

export default function List<T>({
  items,
  list_key,
  ItemRender,
  emptyMessage = "This list is empty",
}: ListProps<T>) {
  if (items.length === 0)
    return (
      <div className="list-empty">
        <h3>{emptyMessage}</h3>
      </div>
    );
  return (
    <div className="list-container">
      {items.map((item, index) => (
        <ItemRender item={item} key={list_key + index} />
      ))}
    </div>
  );
}
