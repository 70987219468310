import { useCallback, useContext, useEffect, useState } from "react";
import "./styles.scss";
import Layout from "../layout/Portal";
import { chatDummy, mesData, notData } from "./data";
import { formatDateToLong } from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { placeholder } from "../../constants/images";
import { AppLoader } from "../../components/AppLoader";
import { useMessages } from "../../hooks/useMessages";
import { v4 as uuidv4 } from "uuid";
import { socket } from "../../socket";

export const Message = ({ isConnected }: any) => {
  const [type, setType] = useState("notifications");
  const [selectedItem, setSelectedItem] = useState<any>("");

  const [id, setId] = useState<any>("");
  const [msg, setMsg] = useState<any>("");

  const [notificationValues, updateNotificationValues] = useState<any>({
    loading: false,
    data: [],
    error: "",
  });

  const [messagesValues, updateMessagesValues] = useState<any>({
    loading: false,
    data: [],
    error: "",
  });

  const fetchNotifications = async (signal: any) => {
    const token = await localStorage.getItem("accessToken");

    const url = `https://pluri-api-service.onrender.com/notification`;

    updateNotificationValues({
      ...notificationValues,
      loading: true,
      error: "",
    });

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
        signal,
      });

      const result = await response.json();
      if (result) {
        updateNotificationValues({
          ...notificationValues,
          data: result.result.notifications,
          loading: false,
        });
      } else {
        updateNotificationValues({
          ...notificationValues,
          loading: false,
          error: result.message ?? "Something went wrong",
          data: [],
        });
      }
    } catch (err: any) {
      console.log(err);
      updateNotificationValues({
        ...notificationValues,
        loading: false,
        error: err.message ?? "Something went wrong",
        data: [],
      });
    }
  };

  const fetchMessageList = async (signal: any) => {
    const token = await localStorage.getItem("accessToken");
    const id = await localStorage.getItem("id");

    if (id) {
      setId(id);
    }

    const url = `https://pluri-api-service.onrender.com/messages/merchants`;

    updateMessagesValues({ ...messagesValues, loading: true, error: "" });

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
        signal,
      });

      const result = await response.json();

      if (result) {
        updateMessagesValues({
          ...messagesValues,
          data: result.result,
          loading: false,
        });
      } else {
        updateMessagesValues({
          ...messagesValues,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateMessagesValues({
        ...messagesValues,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  };

  const {
    messages,
    loading,
    error,
    addMessage,
    updateMessageStatus,
    fetchChatList,
  } = useMessages();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchMessageList(signal);
    fetchNotifications(signal);

    return () => {
      controller.abort();
    };
  }, []);

  // const WS_URL = "https://api.pluritongues.com";

  // const socket = io(`${WS_URL}/messages?userId=${id}`, {
  //   transports: ["websocket"],
  //   // reconnection: true,
  //   // reconnectionAttempts: 5,
  //   // secure: WS_URL.startsWith("https"),
  //   // autoConnect: true,
  //   // timeout: 10000,
  //   // auth: {
  //   //   token,
  //   // },
  // });

  // socket.on("connect", () => {
  //   setIsConnected(socket.connected);
  // });

  // socket.on("disconnected", () => {
  //   setIsConnected(false);

  //   console.log("disconnected from Socket.IO server");
  // });

  // socket.on("connect_error", (error) => {
  //   console.log("Connection failed:", error);
  // });

  const onSend = () => {
    if (!socket) return;
    if (!msg || !selectedItem.userId || !id) {
      alert(!msg ? "Type a message" : "Something went wrong...");
    } else {
      if (socket && isConnected) {
        const newMsg = {
          transaction: uuidv4(),
          content: msg,
          senderUserType: "merchant",
          receiver: selectedItem.userId,
          createdAt: new Date().toISOString(),
          status: "pending",
        };
        addMessage(newMsg);
        socket.emit("sendMessage", {
          transaction: uuidv4(),
          content: msg,
          senderUserType: "merchant",
          receiverId: selectedItem.userId,
        });
        socket.on("messageSent", (response) => {
          if (response) {
            updateMessageStatus(newMsg.transaction, "sent");
          } else {
            updateMessageStatus(newMsg.transaction, "failed");
          }
        });
        socket.on("errorOccurred", (error: any) => {
          console.warn("Connection failed:", error);
          updateMessageStatus(newMsg.transaction, "failed");
        });
        setMsg("");
      } else {
        alert("Something went wrong...");
      }
    }
  };

  socket.on("newMessage", (response: any) => {
    console.log(response);
    addMessage(response);
  });

  return (
    <Layout color={"transparent"}>
      <div className="container">
        <div className="mainContainer">
          <div className="mainTop">
            <p
              onClick={() => {
                setSelectedItem("");
                setType("notifications");
              }}
              className={type === "notifications" ? "topTextPicked" : "topText"}
            >
              Notifications
            </p>
            <p
              onClick={() => {
                setSelectedItem("");
                setType("messages");
              }}
              className={type === "messages" ? "topTextPicked" : "topText"}
            >
              Messages
            </p>
          </div>

          <div className="mappedContent">
            {type === "notifications" ? (
              notificationValues.loading ? (
                <AppLoader />
              ) : !notificationValues?.data ||
                notificationValues.data.length < 1 ? (
                <div className="noData"> No data</div>
              ) : (
                notificationValues?.data?.map((item: any, index: any) => {
                  return (
                    <div
                      onClick={() => setSelectedItem(item)}
                      key={index}
                      className="mapItemContainer"
                    >
                      <div className="inner">
                        <img
                          src="https://media.istockphoto.com/id/2073254261/photo/an-adult-man-is-using-his-smartphone.jpg?s=1024x1024&w=is&k=20&c=xNIjvUR3J7C1fFcCbjq_syI3rDb0loJ_xyvDkZx7PCU="
                          className="image"
                        />
                        <div>
                          <p className="name">{item.name}</p>
                          <p className="content">{item.content}</p>
                        </div>
                      </div>
                      <p className="date">{formatDateToLong(item.date)}</p>
                    </div>
                  );
                })
              )
            ) : messagesValues.loading ? (
              <AppLoader />
            ) : !messagesValues.data || messagesValues.data.length < 1 ? (
              <div className="noData">No data</div>
            ) : (
              messagesValues.data?.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => {
                      fetchChatList(item.userId);
                      setSelectedItem(item);
                    }}
                    key={index}
                    className="mapItemContainer"
                  >
                    <div className="inner">
                      <img
                        src={
                          item.profilePicture
                            ? item.profilePicture
                            : placeholder
                        }
                        className="image"
                        alt=""
                      />
                      <p className="name">{`${item.firstName}  ${item.lastName}`}</p>
                    </div>
                    <div className="messageContent">
                      <p className="content">{item?.lastMessage?.content}</p>
                    </div>

                    <p className="date">
                      {formatDateToLong(item?.lastMessage?.createdAt)}
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {type === "notifications" ? (
          selectedItem ? (
            <div className="detailContainer">
              <div className="header">
                <p className="name">{selectedItem.name}</p>
                <p className="content" onClick={() => setSelectedItem("")}>
                  Close
                </p>
              </div>
              <div className="notContent">
                <p className="dateNote">
                  {formatDateToLong(selectedItem.date)}
                </p>
                <img
                  src="https://media.istockphoto.com/id/2073254261/photo/an-adult-man-is-using-his-smartphone.jpg?s=1024x1024&w=is&k=20&c=xNIjvUR3J7C1fFcCbjq_syI3rDb0loJ_xyvDkZx7PCU="
                  className="imageNot"
                  alt=""
                />
                <p className="fullContent">{selectedItem.content}</p>
              </div>
            </div>
          ) : null
        ) : selectedItem ? (
          <div className="detailContainer">
            <div className="header">
              <p className="name">{`${selectedItem.firstName}  ${selectedItem.lastName}`}</p>
              <p className="content" onClick={() => setSelectedItem("")}>
                Close
              </p>
            </div>
            <div className="messagesContainer">
              {loading ? (
                <AppLoader />
              ) : !messages ? (
                <p className="noData">No data</p>
              ) : (
                messages.map((item: any, index: any) => {
                  return (
                    <div key={index} className="chatsCon">
                      {item.senderUserType === "user" ? (
                        <img
                          src={
                            selectedItem?.profilePicture
                              ? selectedItem?.profilePicture
                              : placeholder
                          }
                          className="image"
                          alt=""
                        />
                      ) : (
                        <img src={placeholder} className="image" alt="" />
                      )}

                      <div>
                        <div className="innerChat">
                          {item.senderUserType === "user" ? (
                            <p className="chatName">{`${selectedItem.firstName}  ${selectedItem.lastName}`}</p>
                          ) : (
                            <p className="chatName">You</p>
                          )}
                          <p className="chatDate">
                            {new Date(item.createdAt).toLocaleTimeString()}
                          </p>
                        </div>
                        <p className="chatContent">{item.content}</p>

                        {item.status ? (
                          item.status === "pending" ? (
                            <FontAwesomeIcon icon={["fas", "clock"]} />
                          ) : item.status === "sent" ? (
                            <FontAwesomeIcon icon={["fas", "check"]} />
                          ) : (
                            <FontAwesomeIcon icon={["fas", "circle-notch"]} />
                          )
                        ) : null}
                      </div>
                    </div>
                  );
                })
              )}
            </div>

            <div className="inputCon">
              <input
                onChange={(e) => {
                  setMsg(e.target.value?.trimStart());
                }}
                value={msg}
                className="inputChat"
                placeholder="Your Message"
              />
              <FontAwesomeIcon
                onClick={() => onSend()}
                className="icon"
                icon={["far", "paper-plane"]}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};
