import { useEffect, useState } from "react";
import Layout from "../layout/Portal";
import "./styles.scss";
import { currencyFormat, isScreenLarge } from "../../utils/helpers";
import { AppLoader } from "../../components/AppLoader";
import { LessonModal } from "./Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "sonner";

interface LessonState {
  error: string;
  data: any;
  loading: boolean;
}
interface LanguagesState {
  error: string;
  data: any;
  loading: boolean;
}
interface DeleteLesson {
  error: string;
  data: any;
  loading: boolean;
}

export const Lessons = () => {
  const [state, updateState] = useState<LessonState>({
    error: "",
    data: [],
    loading: false,
  });
  const [languages, updateLanguages] = useState<LanguagesState>({
    error: "",
    data: [],
    loading: false,
  });

  const [deleteLesson, updateDeleteLesson] = useState<DeleteLesson>({
    error: "",
    data: [],
    loading: false,
  });

  const [language, updateLanguage] = useState("");
  const [dynamicData, setDynamicData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  const fetchLessons = async () => {
    const token = await localStorage.getItem("accessToken");
    const id = await localStorage.getItem("id");
    const url = `https://pluri-api-service.onrender.com/teachers/${id}/lessons`;

    updateState({ ...state, loading: true, error: "" });

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result) {
        updateLanguage(result?.result?.languagesTaught[0]?.name);
        setDynamicData(result?.result?.languagesTaught[0]?.lessons);
        updateState({
          ...state,
          data: result.result,
          loading: false,
        });
      } else {
        updateState({
          ...state,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateState({
        ...state,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  };
  const fetchLanguage = async () => {
    const token = await localStorage.getItem("accessToken");
    updateLanguages({ ...state, loading: true, error: "" });
    const url =
      "https://pluri-api-service.onrender.com/languages/languages-taught";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result) {
        updateLanguages({
          ...state,
          data: result.result,
          loading: false,
        });
      } else {
        updateLanguages({
          ...state,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateLanguages({
        ...state,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  };

  const _deleteHandler = async (id: any) => {
    const token = await localStorage.getItem("accessToken");
    console.log(token);

    const url = `https://pluri-api-service.onrender.com/lessons/${id}`;

    updateDeleteLesson({ ...deleteLesson, loading: true, error: "" });

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      console.warn(result);
      if (result.status) {
        updateDeleteLesson({
          ...deleteLesson,
          data: result.result,
          loading: false,
        });
        fetchLessons();
      } else {
        updateDeleteLesson({
          ...deleteLesson,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);
      updateDeleteLesson({
        ...deleteLesson,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  };

  useEffect(() => {
    fetchLessons();
    fetchLanguage();
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isLargeScreen, setIsLargeScreen] = useState(isScreenLarge());

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(isScreenLarge());

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openModal = () => {
    console.warn(isLargeScreen, isScreenLarge);
    if (isLargeScreen) {
      toast.warning(
        "This action is only available on larger screens. Please switch to a desktop for the best experience.",
        {
          richColors: true,
        }
      );
    } else {
      setIsOpen(true);
    }
  };

  return (
    <Layout color={"transparent"}>
      <h2 className="lessonHeaderTitle">Lessons</h2>
      <br />
      <span className="subTitleHeader">
        Manage your lesson & lesson packages
      </span>

      <div className="titleContainer">
        <div className="languagesContainer">
          {state.data?.languagesTaught?.map((item: any, index: any) => {
            return (
              <div
                key={index}
                className={
                  language === item.name ? "languagePicked" : "language"
                }
                onClick={() => {
                  updateLanguage(item.name);
                  setDynamicData(
                    state.data?.languagesTaught?.filter(
                      (e: any) => e.name === item.name
                    )[0].lessons
                  );
                }}
              >
                <p>{item.name}</p>
              </div>
            );
          })}
        </div>
        {languages.loading ? null : (
          <div className="createBtn" onClick={() => openModal()}>
            + create Lesson
          </div>
        )}
      </div>
      {state.loading || deleteLesson.loading || languages.loading ? (
        <AppLoader />
      ) : state.error ? (
        <div>
          <p>Something went wrong</p>
        </div>
      ) : (
        <div>
          <div className="lessonsContainer">
            {dynamicData?.map((item: any, index: any) => {
              return (
                <div key={index} className="lessonsEach">
                  <div className="lesson">
                    <div>
                      <p className="title">{"Lesson Name"}</p>
                      <p className="subTitleText">{item.name}</p>
                      <p className="title">Description</p>
                      <p className="subTitleText">{item.description}</p>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        className="deleteIcon"
                        icon={["fas", "trash"]}
                        onClick={() => _deleteHandler(item.lessonId)}
                      />
                    </div>
                  </div>
                  <details>
                    <summary className="summary">See Packages</summary>
                    {item.packages?.map((item: any, index: any) => {
                      return (
                        <div key={index} className="leftPackageContainer">
                          <div className="eachSession">
                            <p className="title">{`Quantity`}</p>
                            <p className="subTitle">{` ${item.quantity}`}</p>
                          </div>

                          <div className="eachSession">
                            <p className="title">{`Duration`}</p>
                            <p className="subTitle">{`${item.duration} Minutes`}</p>
                          </div>
                          <div className="eachSession">
                            <p className="title">{`Price`}</p>
                            <p className="subTitle">{`${currencyFormat(
                              item.price / 100
                            )}`}</p>
                          </div>
                        </div>
                      );
                    })}
                  </details>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <LessonModal
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        languages={languages.data.languages}
        fetchLessons={fetchLessons}
      />
    </Layout>
  );
};
