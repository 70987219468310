import React, { useEffect, useState } from "react";
import Layout from "../layout/Portal";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconWhite, placeholder } from "../../constants/images";
import { AppLoader } from "../../components/AppLoader";
import { toast } from "sonner";

export const Profile = () => {
  const [values, updateValues] = useState({
    aboutMe: "",
    aboutMeAsATeacher: "",
    firstName: "",
    lastName: "",
    otherNames: "",
    email: "",
    youtubeUrl: "",
    interest: [],
    spokenLanguage: [],
    languagesSpoken: [
      {
        language: "",
        proficiency: "",
      },
    ],
    workExperiences: [
      {
        organization: "",
        jobRole: "",
        startDate: "",
        endDate: "",
      },
    ],
    educationalQualifications: [
      {
        level: "",
        endDate: "",
        startDate: "",
        institution: "",
        fieldOfStudy: "",
      },
    ],
    dpUrl: "",
  });

  const [value, setValue] = useState({
    data: [],
    error: "",
    loading: false,
    submitError: "",
  });

  const [state, updateState] = useState({
    data: "",
    error: "",
    loading: false,
  });

  const validate = () => {
    // Check required fields
    // if (!values.firstName) return setSubmitError("First Name is required.");
    // if (!values.lastName) return setSubmitError("Last Name is required.");
    // if (!values.email) return setSubmitError("Email Address is required.");
    if (!values.aboutMe) return setSubmitError("Please enter About Me.");
    if (!values.aboutMeAsATeacher)
      return setSubmitError("Please enter About Me As A Teacher.");

    // // Email validation
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(values.email)) {
    //   return setSubmitError("Please enter a valid email address.");
    // }

    // // URL validation for YouTube URL
    // const urlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    // if (values.youtubeUrl && !urlRegex.test(values.youtubeUrl)) {
    //   return setSubmitError("Please enter a valid YouTube URL.");
    // }

    return true; // All validations passed
  };

  const setSubmitError = (message: string) => {
    setValue((prev) => ({ ...prev, submitError: message }));
    return false;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (validate()) {
      const token = await localStorage.getItem("accessToken");
      const id = await localStorage.getItem("id");
      const url = `https://pluri-api-service.onrender.com/teachers/${id}`;

      setValue({ ...value, loading: true, error: "", submitError: "" });

      try {
        const response = await fetch(url, {
          method: "PATCH",
          headers: {
            authorization: `Bearer ${token}` ?? "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            aboutYou: values.aboutMe,
            aboutYouAsTeacher: values.aboutMeAsATeacher,
          }),
        });

        const result = await response.json();
        console.log(result.message);
        if (!result.error) {
          setValue({
            ...value,
            data: result.result,
            loading: false,
            submitError: "",
          });
        } else {
          setValue({
            ...value,
            loading: false,
            submitError: result.message ?? "Something went wrong",
          });
        }
      } catch (err: any) {
        console.log(err);

        setValue({
          ...value,
          loading: false,
          submitError: err.message ?? "Something went wrong",
        });
      }
    }
  };

  const fetchDetails = async () => {
    const token = await localStorage.getItem("accessToken");
    const id = await localStorage.getItem("id");
    const url = `https://pluri-api-service.onrender.com/teachers/${id}`;

    updateState({ ...state, loading: true, error: "" });

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}` ?? "",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      console.log(result.result);
      if (!result.error) {
        updateState({
          ...state,
          data: result.result,
          loading: false,
          error: "",
        });
        updateValues({
          ...values,
          aboutMe: result.result.aboutYou,
          aboutMeAsATeacher: result.result.aboutYouAsTeacher,
          firstName: result.result.firstName,
          lastName: result.result.lastName,
          otherNames: result.result.otherNames,
          email: result.result.email,
          youtubeUrl: result.result.youtubeVideoUrl,
          interest: result.result.interests,
          languagesSpoken: [
            {
              language: result.result.language,
              proficiency: result.result.proficiency,
            },
          ],
          workExperiences: [
            {
              organization: result.result.aboutYou,
              jobRole: result.result.aboutYou,
              startDate: result.result.aboutYou,
              endDate: result.result.dpUrl,
            },
          ],
          educationalQualifications: [
            {
              level: result.result.level,
              endDate: result.result.endDate,
              startDate: result.result.startDate,
              institution: result.result.institution,
              fieldOfStudy: result.result.fieldOfStudy,
            },
          ],
          dpUrl: result.result.dpUrl,
        });
      } else {
        updateState({
          ...state,
          loading: false,
          error: result.message ?? "Something went wrong",
        });
      }
    } catch (err: any) {
      console.log(err);

      updateState({
        ...state,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  };

  const _resetHandler = () => {
    updateValues({ ...values, aboutMe: "", aboutMeAsATeacher: "" });
  };

  const [preview, setPreview] = useState<string | null>(null);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const validTypes = ["image/jpeg", "image/png"];
    const maxSize = 2 * 1024 * 1024;
    if (!validTypes.includes(file.type)) {
      toast.warning("Invalid file type. Only JPEG and PNG are allowed.", {
        richColors: true,
      });
      return;
    }
    if (file.size > maxSize) {
      toast.warning("File size exceeds the maximum limit of 2MB.", {
        richColors: true,
      });
      return;
    }

    const previewUrl = URL.createObjectURL(file);
    setPreview(previewUrl);

    const token = await localStorage.getItem("accessToken");

    const formData = new FormData();
    formData.append("file", file);

    try {
      toast.info("Uploading", {
        richColors: true,
        duration: 3000,
      });
      const response = await fetch(
        "https://pluri-api-service.onrender.com/teachers/profile-picture",
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(
          `Image upload failed: ${response.statusText}. Details: ${errorDetails}`
        );
      }

      const data = await response.json();
      toast.success("Uploaded successfully", {
        richColors: true,
        duration: 2000,
      });
    } catch (error: any) {
      toast.error("Something went wrong", {
        richColors: true,
        duration: 3000,
      });
      console.error("Error uploading image:", error.message);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  console.log(values.dpUrl);

  return (
    <Layout color="transparent">
      <div className="profileMainContainer">
        <div className="profileLeftContainer">
          <div className="rightHeader">
            <p className="topSubTitle">Profile</p>
            <br />
            <p className="topTitle"> Manage your profile & Profile Settings</p>
          </div>
        </div>
        {state.loading ? (
          <AppLoader />
        ) : (
          <div className="profileRightContainer">
            <div className="profilePictureContainer">
              <p className="profilePictureText">Profile Picture</p>
              <div className="profilePictureHeader">
                {preview ? (
                  <img
                    alt=""
                    src={preview}
                    className="placeholderProfilePicture"
                  />
                ) : (
                  <img
                    alt=""
                    src={values.dpUrl ?? placeholder}
                    className="placeholderProfilePicture"
                  />
                )}
                <div>
                  <p className="titleText">
                    This will be displayed to other users when they view your
                    profile or posts.
                    <div className="camera">
                      <FontAwesomeIcon
                        className="cameraIcon"
                        icon={["fas", "camera"]}
                      />
                      <input
                        placeholder=" "
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ position: "absolute", opacity: 0 }}
                      />
                      Upload Photo
                    </div>
                    <p className="sizeFormat">
                      Png or Jpg Format.
                      <br />
                      Max size: 2MB
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="basicInfoContainer">
              <form>
                <p className="sectionTitle">Basic Information</p>
                <label className="labelProfile">
                  First Name <br />
                  <input
                    disabled
                    placeholder="Mike"
                    type="text"
                    value={values.firstName}
                    onChange={(e) =>
                      updateValues({ ...values, firstName: e.target.value })
                    }
                    className="profileInput"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  Last Name <br />
                  <input
                    placeholder="Ezechi"
                    type="text"
                    disabled
                    value={values.lastName}
                    onChange={(e) =>
                      updateValues({ ...values, lastName: e.target.value })
                    }
                    className="profileInput"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  Email Address <br />
                  <input
                    placeholder="mikewarabi@gmail.com"
                    type="text"
                    disabled
                    value={values.email}
                    onChange={(e) =>
                      updateValues({ ...values, email: e.target.value })
                    }
                    className="profileInput"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  YouTube URL <br />
                  <input
                    placeholder="https://youtube.com/user122334/0o000#"
                    type="text"
                    disabled
                    value={values.youtubeUrl}
                    onChange={(e) =>
                      updateValues({ ...values, youtubeUrl: e.target.value })
                    }
                    className="profileInput"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  Interest <br />
                  <input
                    disabled
                    placeholder="interest"
                    type="text"
                    className="profileInput"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  Spoken Language <br />
                  <input
                    placeholder="Spoken Language"
                    type="text"
                    disabled
                    className="profileInput"
                  />
                </label>
              </form>
            </div>

            <div className="basicInfoContainer">
              <form>
                <p className="sectionTitle">Work Experience</p>
                <label className="labelProfile">
                  Organization <br />
                  <input
                    placeholder="Mike"
                    type="text"
                    disabled
                    className="profileInput"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  Job Role <br />
                  <input
                    placeholder="Ezechi"
                    type="text"
                    disabled
                    className="profileInput"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  Start Date
                  <br />
                  <input type="date" disabled className="profileInput" />
                </label>
                <br />
                <label className="labelProfile">
                  End Date <br />
                  <input type="date" disabled className="profileInput" />
                </label>
                <br />
              </form>
            </div>

            <div className="aboutMeContainer">
              <form onSubmit={handleSubmit} onReset={_resetHandler}>
                <p className="sectionTitle">About Me</p>
                <label className="labelProfile">
                  About Me <br />
                  <textarea
                    placeholder="About me"
                    value={values.aboutMe}
                    onChange={(e) =>
                      updateValues({ ...values, aboutMe: e.target.value })
                    }
                    className="profileTextarea"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  Me As A Teacher <br />
                  <textarea
                    placeholder="Me As A Teacher"
                    value={values.aboutMeAsATeacher}
                    onChange={(e) =>
                      updateValues({
                        ...values,
                        aboutMeAsATeacher: e.target.value,
                      })
                    }
                    className="profileTextarea"
                  />
                </label>
                {value.submitError && (
                  <p className="errorMessage">{value.submitError}</p>
                )}
                <div className="aboutBtn">
                  <button className="aboutSaveBtn" type="submit">
                    {value.loading ? <span className="loader"></span> : "Save"}
                  </button>
                  <button className="aboutCancelBtn" type="reset">
                    Cancel
                  </button>
                </div>
              </form>
            </div>

            <div className="certificationContainer">
              <form>
                <p className="sectionTitle">Certifications</p>
                <label className="labelProfile">
                  Name of Certification <br />
                  <input
                    placeholder="name of certification"
                    type="text"
                    disabled
                    className="profileInput"
                  />
                </label>
                <br />
                <label className="labelProfile">
                  Name of Certification <br />
                  <input
                    placeholder="name of certification"
                    type="text"
                    disabled
                    className="profileInput"
                  />
                </label>
                <br />
              </form>
            </div>
            {/* {value.submitError && (
              <p className="errorMessage">{value.submitError}</p>
              )} */}
          </div>
        )}
      </div>
    </Layout>
  );
};
