import React from "react";
import "./balance-card.scss";
interface BalanceCardProps {
  balance: number;
  currency?: string; // Optional currency prop
  onWithdraw: () => void; // Function to handle withdrawal
}

const BalanceCard: React.FC<BalanceCardProps> = ({
  balance,
  currency = "$",
  onWithdraw,
}) => {
  return (
    <div className="balance-card">
      <div className="card-content">
        <h3>Total Balance</h3>
        <p className="balance-amount">
          {currency} {balance.toFixed(2)}{" "}
          {/* Formats balance with two decimal points */}
        </p>

        {/* Withdraw Button */}
        <button className="withdraw-button" onClick={onWithdraw}>
          Withdraw
        </button>
      </div>
    </div>
  );
};

export default BalanceCard;
