import React from "react";
import { loader } from "../constants/gif";
import "./AppLoader.scss";

export const AppLoader = () => {
  return (
    <div className="loaderContainer">
      <img src={loader} className="appLoader" alt="loading..." />
    </div>
  );
};
