export const days = [
  {
    id: 1,
    day: "Monday",
  },
  {
    id: 2,
    day: "Tuesday",
  },
  {
    id: 3,
    day: "Wednesday",
  },
  {
    id: 4,
    day: "Thursday",
  },
  {
    id: 5,
    day: "Friday",
  },
  {
    id: 6,
    day: "Saturday",
  },
  {
    id: 7,
    day: "Sunday",
  },
];

export const dummySession = [
  {
    end: "15:00",
    start: "14:30",
    day: "10/23/2024",
  },
  {
    end: "18:00",
    start: "16:00",
    day: "10/23/2024",
  },
  {
    end: "20:00",
    start: "18:30",
    day: "10/23/2024",
  },
  {
    end: "15:00",
    start: "14:30",
    day: "10/28/2024",
  },
  {
    end: "18:00",
    start: "16:00",
    day: "10/28/2024",
  },
  {
    end: "20:00",
    start: "18:30",
    day: "10/28/2024",
  },
];
