// This the card that introduces the counts
import React from "react";
import "./card.scss";

interface CardProps {
  totalTransactions: number;
}

const Card: React.FC<CardProps> = ({ totalTransactions }) => {
  return (
    <div className="transactions-card">
      <div className="card-content">
        <h3>Upcoming Classes</h3>
        <p className="transaction-count">
          {totalTransactions} {/* Display the total number of transactions */}
        </p>
      </div>
    </div>
  );
};

export default Card;
